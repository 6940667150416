import React from "react"
import CardContainer from "./cardContainer"
import FormContainer from "./formContainer"
import PopupName from "./popupName"
import Button from "./button"
import { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import Exclamination from "../../icons/exclamation.svg"
import TryPdf from "../shared/TryPdf"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import { navigate } from "gatsby"

const Center = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function ResetFailed() {
  const [key, setKey] = useState("")

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    setKey(urlParams.get("key"))
  }, [])

  return (
    <Center>
      <CardContainer>
        <FormContainer>
          <PopupName>Something went wrong</PopupName>
          <Exclamination />
          <p style={{ textAlign: `center`, marginTop: `26px` }}>
            Your invite doesn't exist or is expired, send request again
          </p>
        </FormContainer>

        <Button action={() => navigate(`/password-reset/`)}>
          Resend Reset request
        </Button>
      </CardContainer>
    </Center>
  )
}

export default ResetFailed
